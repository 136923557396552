import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import React from "react";
import { UserProfileForm } from "../components/user-profile-form";
import { PageLayout } from "../components/page-layout";
import { MessageUserProfile } from "../models/message-user-profile";
import { CopyToClipboardButton } from "../components/buttons/copy-to-clipboard-button";

import {
  getProfileResource,
  saveProfileResource,
} from "../services/message.service";

export const ProfilePage: React.FC = () => {
  document.title = "Subscribe for eDataPole services";
  const { user, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("n/a");
  const [message, setMessage] = useState<MessageUserProfile>();
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);

  async function handleSave(newProfileData: MessageUserProfile) {
    const { data, error } = await saveProfileResource(
      accessToken,
      newProfileData
    );

    //    setPosts([newPost, ...posts]);
  }

  useEffect(() => {
    let isMounted = true;
    const getMessage = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getProfileResource(
        accessToken,
        user?.email
      );

      if (!isMounted) {
        return;
      }
      if (data) {
        let o: MessageUserProfile = data as MessageUserProfile;
        setMessage(o);
        setAccessToken(accessToken);
        setIsLoading(false);
      }

      if (error) {
        setError(JSON.stringify(error, null, 2));
      }
    };

    getMessage();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);

  if (!user) {
    return null;
  }
  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (message != null) {
    message.email = user.email as string;
    message.id = user.sub as string;
    message.state_list = "NY,FL,CT,VA";
    message.ticker_list = "MMM,ATVI";
  } else {
    return <div>Loading ...</div>;
  }

  return (
    <PageLayout>
      <div className="content-layout">
        <div className="content__body">
          <UserProfileForm
            onSave={handleSave}
            user={message as MessageUserProfile}
          />
        </div>
        <div className="ufrm-frame">
          <div className="ufrm-style-row width_100pr">
            <label>
              <span className="ufrm-style-heading ufrm-title_uline_dark">
                API Access Key
              </span>
              <span className="ufrm-style-heading">
                {" "}
                - ( expiring in {Math.floor(message.exp_in_h / 24)} days and{" "}
                {Math.floor(message.exp_in_h % 24)} hours)
              </span>
              <p></p>
            </label>
            <CopyToClipboardButton content={message.api_key} />
          </div>
          <textarea
            readOnly
            placeholder="access_token"
            className="ufrm-style-access_token_textarea"
            defaultValue={message.api_key}
          />
        </div>
      </div>
    </PageLayout>
  );
};
