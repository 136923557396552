import React from "react";
import { EDataFeature } from "./edata-feature";
import logo1 from "../images/AI1 SM.png";
import logo2 from "../images/APIint2 SM.png";
import logo3 from "../images/Mail2 SM.png";
import logo4 from "../images/SecExtr1 SM.png";

export const EDataFeatures: React.FC = () => {
  const featuresList = [
    {
      id: 1,
      title: "New Registered Entities API",
      description:
        "Newly registered or recently dissolved business entities contact information: Gain access to the latest contact and industry classifications information.",

      resourceUrl: "/services",
      icon: logo2,
    },
    {
      id: 2,
      title: "New registered entities - eMail Delivery Service",
      description:
        "A weekly email delivery service that provides you with the freshest data on newly registered companies! This service is designed to keep you ahead of the competition by giving you early access to contact information for new businesses.",
      resourceUrl: "/services",
      icon: logo3,
    },
    {
      id: 3,
      title:
        "Securities and Exchange Commission (SEC) 10-K,3,4,5,8-K,13f Forms Filing extract API",
      description:
        "Extracted data is in plain TXT format that you can use in risk analysis and profit prediction. we provide an AI Insights into potential returns.",
      resourceUrl: "/services",
      icon: logo4,
    },
    {
      id: 4,
      title: "Real-Time SEC Filing Notifications and AI Insights",
      description:
        "Never miss an update with our real-time SEC filing notification service. Tailor your preferences to receive instant alerts when a new SEC form is filed for your preselected list of companies.",
      resourceUrl: "/services",
      icon: logo1,
    },
  ];

  return (
    <div className="auth0-features">
      <h2 className="auth0-features__title">Explore eDataPole Features</h2>
      <div className="auth0-features__grid">
        {featuresList.map((feature) => (
          <EDataFeature
            key={feature.id}
            title={feature.title}
            description={feature.description}
            resourceUrl={feature.resourceUrl}
            icon={feature.icon}
          />
        ))}
      </div>
    </div>
  );
};
