import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { MobileNavBarTab } from "./mobile-nav-bar-tab";

interface MobileNavBarTabsProps {
  handleClick: () => void;
}

export const MobileNavBarTabs: React.FC<MobileNavBarTabsProps> = ({
  handleClick,
}) => {
  const { isAuthenticated } = useAuth0();

  const tbaAdminSolved: boolean = false;
  const tbdDashboard: boolean = true;
  const tbFindBsn: boolean = false;
  return (
    <div className="mobile-nav-bar__tabs">
      <MobileNavBarTab path="/about" label="About" handleClick={handleClick} />
      <MobileNavBarTab
        path="/services"
        label="Services"
        handleClick={handleClick}
      />
      {isAuthenticated && tbdDashboard && (
        <MobileNavBarTab
          path="/dashboard"
          label="SEC Filings Risk Analysis"
          handleClick={handleClick}
        />
      )}

      <MobileNavBarTab
        path="/newbisinesses"
        label="New Businesses"
        handleClick={handleClick}
      />
      <MobileNavBarTab
        path="/pricing"
        label="Pricing"
        handleClick={handleClick}
      />
      <MobileNavBarTab
        path="/contact"
        label="Contact"
        handleClick={handleClick}
      />

      {isAuthenticated && (
        <>
          {tbFindBsn && (
            <MobileNavBarTab
              path="/findbisiness"
              label="Find Business"
              handleClick={handleClick}
            />
          )}
          <MobileNavBarTab
            path="/profile"
            label="Profile"
            handleClick={handleClick}
          />

          {tbaAdminSolved && (
            <MobileNavBarTab
              path="/admin"
              label="Admin"
              handleClick={handleClick}
            />
          )}
        </>
      )}
    </div>
  );
};
