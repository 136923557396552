import React, { FC, Fragment } from "react";
import { CopyBlock, dracula } from "react-code-blocks";
var error_data_1 = {
  error: "invalid_token",
  error_description: "Signature has expired",
  message: "Bad credentials.",
  response_status: 401,
  response_reason: "UNAUTHORIZED",
};
var error_data_2 = {
  response_status: 999,
  response_reason:
    "ConnectionRefusedError(10061, 'No connection could be made because the target machine actively refused it', None, 10061, None)",
};

var data = {
  list: [
    {
      corp_name: "HIRE ORBITT LLC",
      county: "Westchester",
      dos_id: "7338074",
      eff_date: "2024-07-27",
      entity_type: "DOMESTIC LIMITED LIABILITY COMPANY",
      for_juris: "NY",
      id: 3922,
      sop_addr1: "95 Circle Drive",
      sop_addr2: "",
      sop_city: "Hastings on Hudson",
      sop_name: "Hire Orbitt LLC",
      sop_state: "NY",
      sop_zip: "10706",
    },
    {
      corp_name: "LYLA SPA INC.",
      county: "New York",
      dos_id: "7339633",
      eff_date: "2024-08-01",
      entity_type: "DOMESTIC BUSINESS CORPORATION",
      for_juris: "NY",
      id: 7218,
      sop_addr1: "31 DIVISION STREET",
      sop_addr2: "BASEMENT",
      sop_city: "NEW YORK",
      sop_name: "RONG WU",
      sop_state: "NY",
      sop_zip: "10002",
    },
  ],
  response_status: 200,
  response_reason: "OK",
};

export const SrvApiTab: FC<{}> = () => {
  const github =
    "https://github.com/Ikopeikin32/get-latest-business-entities-records-sample";
  const sample_api_link =
    "https://www.edatapole.com/api/messages/bsns?st=NY&after=2024-06-15&new=1&api_key=YOUR_API_KEY";
  const code_sample: string = `
"""Module providing a function making  call eDataPole REST API."""

import http.client
import sys
import json
import urllib.parse

RSP_STATUS = "response_status"
RSP_REASON = "response_reason"


def fetch_entities(host, port, access_token, state, after_date, new_or_closed=1):
    """This is a function to fetch  new open corporations"""
    try:

        conn = http.client.HTTPConnection(host, port)
        # Connect to host
        conn.connect()

        query = {
            "st": state,
            "after": after_date,
            "new": new_or_closed,
            "api_key": access_token,
        }
        params = "/api/messages/bsns?" + urllib.parse.urlencode(query)

        print(params)
        # This will send a request to the server using the HTTP request
        # GET method /api/messages/bsns?st=...& after=...&new=...
        conn.request("GET", params)
        response = conn.getresponse()
        data = response.read()
        entity_list = json.loads(data)
        entity_list[RSP_STATUS] = response.status
        entity_list[RSP_REASON] = response.reason
        return entity_list
    except Exception as e:
        print(e)
        error = {
            RSP_STATUS: 999,
            RSP_REASON: e,
        }
        return error
    finally:
        # Close the connection to the server.
        conn.close()


if __name__ == "__main__":
    # replace with your access code.
    # You can find access code on https://www.eDataPole.com/profile paqe.
    api_key = "YOUR API KEY"
    host = "edatapole.com"
    port = 80
    # GET request parameters
    # State. Currently only NY and FL are available
    state = "NY"
    # Fetch entities filed since this date
    after_date = "2024-07-20"
    # Specify new and closed entities. 1 fro new, and 0 for closed
    new_or_closed = 1
    json_response = fetch_entities(
        host, port, api_key, state, after_date, new_or_closed
    )
    print(json_response)
    if json_response[RSP_STATUS] != 200:
        print("Exception fetching data")
        print(json_response[RSP_STATUS], json_response[RSP_REASON])
        sys.exit()
    # if request is successful
    if "list" in json_response:
        entities = json_response["list"]
        print("-----------------Returned entities-------------")
        for entity in entities:
            print(entity["id"], entity)
     `;
  const img = `https://storage.googleapis.com/rapidapi-documentation/connect-on-rapidapi-light.png`;
  return (
    <Fragment>
      <h3>
        New Registered Entities API{" "}
        <a
          href="https://rapidapi.com/business-connect-business-connect-default/api/newly-registered-business-entities"
          target="_blank"
        >
          <img src={img} width="215" alt="Connect on RapidAPI" />
        </a>
      </h3>

      <p>API Endpoint: https://www.edatapole.com/api/messages/bsns</p>
      <ul className="hero-banner--ul">
        Request parameters:
        <li className="hero-banner--li">st : State of company location</li>
        <li className="hero-banner--li">
          after : Fetch records filed after specified date. Cannot be more then
          one month back. System will correct the date in other case
        </li>
        <li className="hero-banner--li">
          new : Fetch records of new registered entities/became not active (1 /
          0).
        </li>
      </ul>
      <p>
        Sample of URL parameters string is
        <strong>
          {" "}
          <a
            className="edata_a"
            href={sample_api_link}
            rel="noopener noreferrer"
            target="_blank"
          >
            https://www.edatapole.com/api/messages/bsns?st=NY&after=2024-06-15&new=1&api_key=YOUR_API_KEY
          </a>
        </strong>
        {"   "} This is a demo key. Find your API Key on your profile page. You
        can find a sample code on{" "}
        <a
          className="edata_a"
          href={github}
          rel="noopener noreferrer"
          target="_blank"
        >
          GitHub
        </a>
      </p>
      <div>
        <CopyBlock text={code_sample} language="python" theme={dracula} />
      </div>
      <p></p>
      <p>
        <strong>The response in JSON format that list businesses:</strong>
      </p>
      <div>
        <pre className="edata_pre"> {JSON.stringify(data, null, 2)}</pre>
      </div>
      <p>
        <strong>Samples of responses in case of failure:</strong>
      </p>
      <div>
        <span>
          <strong>Expired/invalid token:</strong>
        </span>
        <pre className="edata_pre">{JSON.stringify(error_data_1, null, 2)}</pre>
      </div>
      <div>
        <span>
          <strong>Connection failure:</strong>
        </span>

        <pre className="edata_pre">{JSON.stringify(error_data_2, null, 2)}</pre>
      </div>
    </Fragment>
  );
};
