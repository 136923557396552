import React from "react";
import PropTypes from "prop-types";
/*import { ITooltip } from "../configuration";*/

export interface ITooltip {
  content: string;
  children?: JSX.Element;
  disabled: boolean;
}

export const Tooltip = ({
  content,
  /*  tooltipRef,*/
  disabled,
  children,
}: ITooltip) => {
  if (content === "") return null;

  return (
    <div className="tooltipContainer">
      <div
        className={`tooltip${
          disabled ? " disabled" : ""
        } `} /*ref={tooltipRef}*/
      >
        {content}
      </div>
      {children}
    </div>
  );
};

Tooltip.propTypes = {
  content: PropTypes.string.isRequired,
  tooltipRef: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
};
