import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import DataTable, {
  createTheme,
  defaultThemes,
} from "react-data-table-component";
import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import { getDashboardResource } from "../services/message.service";
import { SecFilings } from "../models/sec-filings";
import { SecFiling } from "../models/sec-filing";
import { ImageComponent } from "../components/image-component";

/*

      --edata-light: #e1f7f5;
  --edata-lightdark: #b0def3;
  --edata-darklight: #0e46a3;
  --edata-dark: #1e0342;
  --edata-t-dark: black;
  --edata-t-light_spot: #6ef169;*/
export const DashboardPage: React.FC = () => {
  // createTheme creates a new theme named solarized that overrides the build in dark theme
  document.title =
    "Risk Analysis for 10-K SEC Filing. AI Predicted return in 5 days";
  createTheme(
    "solarized",
    {
      rows: {
        fontSize: "20px",
      },
      text: {
        primary: "black",
        secondary: "#1e0342",
      },
      background: {
        default: "#b0def3",
      },
      context: {
        background: "#cb4b16",
        text: "black",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Ticker",
      selector: (row: any) => row.ticker,
      sortable: true,
      width: "100px",
    },
    {
      name: "CIK",
      selector: (row: any) => row.CIK,
      sortable: true,
      width: "150px",
    },
    {
      name: "Name",
      selector: (row: any) => row.name,
      sortable: true,
      width: "300px",
    },
    {
      name: "Filing Date",
      selector: (row: any) => toDate(row.date_filed),

      /*  Intl.DateTimeFormat("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }).format(row.date_filed),*/
    },
    {
      name: "Link to Filing Detail",
      selector: (row: any) => row.edgar_link,
      cell: (row: any) => (
        <a className="table__hyperlink" href={row.edgar_link} target="_blank">
          {row.edgar_filing_code}
        </a>
      ),
    },
    {
      name: <div>Predicted Return in 5 days (%)</div>,
      selector: (row: any) => (row.predicted_return * 100).toFixed(1),
      sortable: true,
      width: "150px",
      conditionalCellStyles: [
        {
          when: (row: any) => row.predicted_return < -0.005,
          style: {
            backgroundColor: "red",
            color: "white",
            "&:hover": {
              cursor: "not-allowed",
            },
          },
        },
        {
          when: (row: any) =>
            row.predicted_return >= -0.005 && row.predicted_return <= 0.005,
          style: {
            backgroundColor: "#b0def3",
            color: "black",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row: any) => row.predicted_return >= 0.005,
          style: {
            backgroundColor: "green",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
  ];

  const [message, setMessage] = useState<SecFilings | null>(null);

  const { getAccessTokenSilently } = useAuth0();

  const toDate = (date: any) => {
    let dateParts: string[] = date.split(" ");
    return dateParts[0];
  };

  useEffect(() => {
    let isMounted = true;

    const getMessage = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getDashboardResource(accessToken);

      if (!isMounted) {
        return;
      }

      if (data) {
        setMessage(data);
        /*setFilings(data["list"]);*/
      }

      /*if (error) {
        setMessage(JSON.stringify(error, null, 2));
      }*/
    };

    getMessage();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);
  /*{props?.data?.name}*/
  const ExpandableComponent = (props: any) => (
    <div>
      <ImageComponent
        session_id={props?.data?.process_session_id}
        cik={props?.data?.CIK}
        filing_code={props?.data?.edgar_filing_code}
      />{" "}
    </div>
  );

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Risk Analysis for 10-K SEC Filing (Demo)
        </h1>
        <div className="content__body">
          <DataTable
            columns={columns}
            data={message?.list!}
            theme="solarized"
            /*highlightOnHover
            pointerOnHover
            pagination
            selectableRows*/
            expandableRows
            expandableRowsComponent={ExpandableComponent}
          />
        </div>
      </div>
    </PageLayout>
  );
};
