export const LANGUAGES = [
  { label: 'Chinese', code: 'zh' },
  { label: 'English', code: 'en' },
  { label: 'Italian', code: 'it' },
  { label: 'Deutsche', code: 'de' },
  { label: 'Dutch', code: 'nl' },
  { label: 'French', code: 'fr' },
  { label: 'Spanish', code: 'es' },
  { label: 'Arabic', code: 'ar' },
];
