import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import DataTable, {
  createTheme,
  defaultThemes,
} from "react-data-table-component";
import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import { findBusinessByName } from "../services/message.service";
import { FindBsns } from "../models/find-bsns";
import { FindBsnItem } from "../models/find-bsn-item";
import { BsnDetailComponent } from "../components/bsn-detail-component";
import { FindBsnForm } from "../components/find-bsn-form";
import { MessageBsnParams } from "../models/message-bsn-params";
export const FindBusinessPage: React.FC = () => {
  // createTheme creates a new theme named solarized that overrides the build in dark theme
  document.title = "Find companies in State's registrations";
  createTheme(
    "solarized",
    {
      rows: {
        fontSize: "20px",
      },
      text: {
        primary: "black",
        secondary: "#1e0342",
      },
      background: {
        default: "#b0def3",
      },
      context: {
        background: "#cb4b16",
        text: "black",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Name",
      selector: (row: any) => row.name,
      sortable: true,
      width: "350px",
    },
    {
      name: "Address",
      selector: (row: any) => row.address,
      width: "350px",
    },
    {
      name: "ID Number",
      selector: (row: any) => row.edgar_link,
      cell: (row: any) => (
        <a className="table__hyperlink" href={row.href} target="_blank">
          {row.dos_id}
        </a>
      ),
    },
    {
      name: "State",
      selector: (row: any) => row.state,
      sortable: true,
      width: "100px",
    },
  ];
  const { user } = useAuth0();
  const [message, setMessage] = useState<FindBsns | null>(null);

  const toDate = (date: any) => {
    let dateParts: string[] = date.split(" ");
    return dateParts[0];
  };

  async function handleSave(bsn: MessageBsnParams) {
    const { data, error } = await findBusinessByName(bsn);
    setMessage(data);
  }

  /*{props?.data?.name}*/
  const ExpandableComponent = (props: any) => (
    <div>
      <BsnDetailComponent
        state={props?.data?.state}
        dos_id={props?.data?.dos_id}
      />{" "}
    </div>
  );

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Find companies in State's registrations
        </h1>

        <div className="content__body">
          <FindBsnForm onSave={handleSave} id={user?.sub} />
        </div>

        <div className="content__body">
          <DataTable
            columns={columns}
            data={message?.list!}
            theme="solarized"
            /*highlightOnHover
            pointerOnHover
            pagination
            selectableRows
            expandableRows
            expandableRowsComponent={ExpandableComponent}*/
          />
        </div>
      </div>
    </PageLayout>
  );
};
