import React, { EventHandler, useState } from "react";
import { selectStyle } from "../custom-styles/select-style";
import { Tooltip } from "../components/buttons/tooltip";

import Select, {
  MultiValue,
  components,
  ValueContainerProps,
} from "react-select";

import { FieldError, useForm } from "react-hook-form";
import { MessageNewBsnParams } from "../models/message-new-bsn-params";

export type Option = {
  value: string;
  label: string;
};

const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<Option>) => {
  let [values, input] = children as any;

  if (Array.isArray(values)) {
    const plural = values.length === 1 ? "y" : "ies";
    values = `${values.length} industr${plural} selected`;
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

interface BsnProps {
  id?: string;
  email?: string;
  pref_industry_list: string;
  onSave: (params: MessageNewBsnParams) => void;
}
export const NewBsnFilter: React.FC<BsnProps> = ({
  id,
  email,
  pref_industry_list,
  onSave,
}) => {
  let tmp = {
    sic_groups: [],
    state: "CT",
    after: "",
  };

  var curr = new Date();
  curr.setDate(curr.getDate() - 7);
  var date = curr.toISOString().substring(0, 10);

  const [radioState, setradioState] = useState<string>("CT");
  const [dateAfter, setDateAfter] = useState(date);

  const [bsn_params, setMessageBsnParams] = useState<MessageNewBsnParams>(
    tmp as MessageNewBsnParams
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<MessageNewBsnParams>();

  const statesOps = [
    { view: "CT", value: "CT" },
    { view: "FL", value: "FL" },
    { view: "VA", value: "VA" },
    { view: "NY", value: "NY" },
  ];

  function getClassifiedBy(state: string) {
    if (state === "CT" || state === "VA") {
      return null;
    }

    return (
      <Tooltip
        disabled={false}
        content={
          "We used the AI-driven classification for New York and Florida"
        }
      >
        <div className="t-bold t-red">&#x24BE;</div>
      </Tooltip>
    );
  }

  const data: Option[] = [
    { value: "0", label: "Undefined" },
    { value: "11", label: "Agriculture, Forestry, Fishing and Hunting" },
    { value: "21", label: "Mining, Quarrying, and Oil and Gas Extraction" },
    { value: "22", label: "Utilities" },
    { value: "23", label: "Construction" },
    { value: "31", label: "Food and Textile Manufacturing" },
    { value: "32", label: "Materials Manufacturing" },
    { value: "33", label: "Finished Product Manufacturing" },
    { value: "42", label: "Wholesale Trade" },
    { value: "44", label: "Retail Trade" },
    { value: "45", label: "Retail Trade" },
    { value: "48", label: "Transportation and Warehousing" },
    { value: "49", label: "Transportation and Warehousing" },
    { value: "51", label: "Information" },
    { value: "52", label: "Finance and Insurance" },
    { value: "53", label: "Real Estate and Rental and Leasing" },
    { value: "54", label: "Professional, Scientific, and Technical Services" },
    { value: "55", label: "Management of Companies and Enterprises" },
    {
      value: "56",
      label:
        "Administrative and Support and Waste Management and Remediation Services",
    },
    { value: "61", label: "Educational Services" },
    { value: "62", label: "Health Care and Social Assistance" },
    { value: "71", label: "Arts, Entertainment, and Recreation" },
    { value: "72", label: "Accommodation and Food Services" },
    { value: "81", label: "Other Services (except Public Administration)" },
    {
      value: "92",
      label: "Public Administration (not covered in economic census)",
    },
  ];
  /*
  const data: Option[] = [
    { label: "0100-0999	Agriculture, Forestry and Fishing", value: "1" },
    { label: "1000-1499	Mining", value: "10" },
    { label: "1500-1799	Construction", value: "15" },
    
    { label: "2000-3999	Manufacturing", value: "20" },
    {
      label:
        "4000-4999	Transportation, Communications, Electric, Gas and Sanitary service",
      value: "40",
    },
    { label: "5000-5199	Wholesale Trade", value: "50" },
    { label: "6000-6799	Finance, Insurance and Real Estate", value: "60" },
    { label: "7000-8999	Services", value: "70" },
    { label: "9100-9729	Public Administration", value: "91" },
    { label: "9900-9999	Nonclassifiable", value: "99" },
  ];
*/

  const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateAfter(e.currentTarget.value);
  };

  const onRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setradioState(e.currentTarget.value);
  };

  let selected: Option[] = [];
  pref_industry_list
    .split(",")
    .map((v) => selected.push({ value: v, label: "" }));

  const [selectedOption, setSelectedOption] = useState<MultiValue<{
    value: string;
    label: string;
  }> | null>(selected);

  const submitRequest = () => {
    console.log("submit");
    bsn_params.after = dateAfter;
    bsn_params.state = radioState;
    bsn_params.sic_groups = selectedOption!.map((a) => a.value);

    onSave(bsn_params);
  };
  /*<div>{selectedOption!.map((e) => e.value).join(",")}</div> */
  return (
    <div className="bsn-flt-style">
      <div className="bsn-flt-frame_grid">
        <div className="bsn-flt-frame_grid_block">
          <div className="bsn-flt-style-row">
            <label className="ufrm-style-label">State:</label>

            {statesOps.map(({ view: title, value: code }: any) => {
              return (
                <div>
                  <input
                    type="radio"
                    value={code}
                    name={code}
                    checked={code === radioState}
                    onChange={(e) => onRadioChange(e)}
                  />
                  {title}
                </div>
              );
            })}
          </div>
        </div>

        <div className="bsn-flt-frame_grid_block">
          <div className="bsn-flt-style-row">
            <label className="ufrm-style-label" htmlFor="title">
              Registered since:
            </label>
            <input
              className="ufrm-style-input-field"
              placeholder="after"
              type="date"
              id="after"
              defaultValue={date}
              onChange={(e) => onDateChange(e)}
            />
          </div>
        </div>
        <div className="bsn-flt-frame_grid_block">
          <div className="bsn-flt-style-row">
            <label className="ufrm-style-label" htmlFor="title"></label>

            <Select
              className="bsn-flt-style-select-field"
              options={data}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ ValueContainer }}
              isMulti
              name="SIC groups"
              classNamePrefix="select"
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              /*theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                height: "20px",
                colors: {
                  ...theme.colors,
                  primary25: "lightgrey",
                  primary: "black",
                },
              })}*/
              styles={selectStyle}
            />
            <label htmlFor="AI">{getClassifiedBy(radioState)}</label>
          </div>
        </div>

        <div className="bsn-flt-frame_grid_block">
          <div className="bsn-flt-style-row">
            <label className="ufrm-style-label">
              <span className="ufrm-style-label-span"> </span>
            </label>
            <button
              type="button"
              disabled={isSubmitting}
              className="bsn-flt-style_button"
              onClick={(): void => submitRequest()}
            >
              Lookup
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
