import React, { useEffect, useState } from "react";
import { fetchNewBusinessesDetails } from "../services/message.service";
import { MessageBsnParams } from "../models/message-bsn-params";
import { FindBsns } from "../models/find-bsns";
import { FindBsnItem } from "../models/find-bsn-item";
/*import ReactDOM from "react-dom";*/
type Props = {
  state: string | undefined;
  dos_id: string | undefined;
  api_key: string | undefined;
};

export function NewBsnDetailComponent({ state, dos_id, api_key }: Props) {
  const bsn: MessageBsnParams = {
    state: state,
    dos_id: dos_id,
    api_key: api_key,
    name: "",
  };

  //agents
  const filedsMap: { [key: string]: string } = {
    corp_name: "Company Name",
    county: "Country",
    dos_id: "DOS identification number",
    eff_date: "Corp Date",
    entity_type: "Entity Type",
    industry: "Industry",
    naics: "NAICS code",
    sic: "SIC code",
    sop_addr1: "Address 1",
    sop_addr2: "Address 2",
    sop_city: "City",
    sop_state: "State",
    sop_zip: "Zip",
    email: "Email",
  };

  const [message, setMessage] = useState<FindBsns | null>(null);
  const [findBsnItem, setFindBsnItem] = useState<FindBsnItem>();
  const [officerItem, setOfficerItem] = useState<[{ [key: string]: string }]>([
    {},
  ]);
  const [agentsItem, setAgentsItem] = useState<[{ [key: string]: string }]>([
    {},
  ]);

  const keys = Object.keys(filedsMap);

  useEffect(() => {
    let isMounted = true;

    const getMessage = async () => {
      const { data, error } = await fetchNewBusinessesDetails(bsn);

      if (!isMounted) {
        return;
      }

      if (data) {
        setMessage(data);
        console.log(data);
        if (data.list.length > 0) {
          setFindBsnItem(data.list[0]);
          if (data.list[0].agents != null && data.list[0].agents !== "") {
            let o = JSON.parse(data.list[0].agents);
            setAgentsItem(o);
          } else {
            setAgentsItem([
              { fname: data.list[0].sop_name, type: "Individual" },
            ]);
          }
          if (data.list[0].officers != null && data.list[0].officers !== "") {
            let o = JSON.parse(data.list[0].officers);
            setOfficerItem(o);
          }
        }
      }
    };
    getMessage();
    return () => {
      isMounted = false;
    };
  }, []);

  if (message == null || message!.list.length <= 0) {
    return <></>;
  }

  return (
    <div className="subs-plan-style">
      <div className="subs-plan-frame_grid">
        <div className="subs-plan-frame_grid_block">
          <div className="table_component">
            <table>
              <caption>Details</caption>
              <tbody>
                {keys!.map((key, i) => (
                  <tr key={i}>
                    <td>{filedsMap[key]}</td>
                    <td>{findBsnItem![key]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="subs-plan-frame_grid_block">
          <div className="table_component">
            <table>
              <caption>Officers</caption>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Title</th>
                </tr>
              </thead>
              <tbody>
                {officerItem!.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {item!.hasOwnProperty("fname") ? item!["fname"] : ""}
                    </td>
                    <td>
                      {item!.hasOwnProperty("lname") ? item!["lname"] : ""}
                    </td>
                    <td>
                      {item!.hasOwnProperty("title") ? item!["title"] : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="subs-plan-frame_grid_block">
          <div className="table_component">
            <table>
              <caption>Agents</caption>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {agentsItem!.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {item!.hasOwnProperty("fname") ? item!["fname"] : ""}
                    </td>
                    <td>
                      {item!.hasOwnProperty("lname") ? item!["lname"] : ""}
                    </td>
                    <td>{item!.hasOwnProperty("type") ? item!["type"] : ""}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
