import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../images/service.png";

export const HeroBanner: React.FC = () => {
  const { isAuthenticated } = useAuth0();


  document.title =
    "Get newly registered business entities. Extract 10-K, 3, 4, 5 , 8-K, 13f SEC filing forms, AI risk analysis and profit predict";
  return (
    <div className="hero-banner--edata-dark-lightdark">
      <div className="hero-banner__logo">
        <img className="hero-banner__image" src={logo} alt="services" />
      </div>

      <h1 className="hero-banner__headline">
        Unlock Your Business's Potential - Elevate Your Growth Today!
      </h1>
      <p className="hero-banner__description">
        We are excited to offer you a unique suite of services designed to
        catapult your business to the forefront of your industry.
      </p>
      <div className="hero-banner__description">
        <p>What We Offer:</p>
        <ul className="hero-banner--ul">
          <li className="hero-banner--li">
            <span className="t-light_spot">
              Newly registered or recently dissolved business entities contact
              information:{" "}
            </span>
            Gain access to the latest contact information.
          </li>
          <li className="hero-banner--li">
            Integration with{" "}
            <span className="t-light_spot">Lead Mail Campaign Services</span>:
            Streamline your processes with our end-to-end campaign solutions.
          </li>
          <li className="hero-banner--li">
            <span className="t-light_spot">
              Access to SEC filings (EDGAR):{" "}
            </span>
            Subscribe for real time filing notification and use 10-K, 3, 4, 5 ,
            8-K, 13f form extracted information in risk analysis and profit
            prediction. AI Insights into potential returns.
          </li>
          <li className="hero-banner--li">
            <span className="t-light_spot">API Integration</span>: Connect
            directly with our services through our API. If you don’t have a
            developer on hand, we offer a no-code solution to get you started
            effortlessly.
          </li>
          <li className="hero-banner--li">
            <span className="t-light_spot">Custom Solutions</span>: Unique
            business challenges require unique solutions. If you require a
            tailored approach, don't hesitate to reach out to discuss how we can
            address your specific needs.
          </li>
        </ul>
      </div>

      {!isAuthenticated && (
        <>
          <div className="page-footer-info__button">
            <a
              id="create-account-button"
              className="button button--secondary"
              href="/profile"
              rel="noreferrer noopener"
            >
              Subscribe →
            </a>
          </div>
        </>
      )}
    </div>
  );
};
