import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import { submitTicket } from "../services/message.service";
import { MessageTicket } from "../models/message-ticket";
import { ContactForm } from "../components/contact-form";

export const ContactUsPage: React.FC = () => {
  const { user } = useAuth0();

  async function handleSave(newTicket: MessageTicket) {
    const { data, error } = await submitTicket(newTicket);
  }
  const [unsubMsg, setUnsubMsg] = useState("");

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  let cust_id = params.get("cust");
  if (cust_id != null) {
    let unsub = params.get("unsub");
    if (unsub === "1" && unsubMsg === "") {
      setUnsubMsg(
        "You are successfully unsubscribed from our marketing notification list."
      );
    }
  }

  return (
    <PageLayout>
      <div className="ufrm-frame">
        {unsubMsg != "" && (
          <div className="hero-banner__headline">
            <br />
            <br />
            <h3>{unsubMsg}</h3>
            <h3>Thank you</h3>
            <br />
            <br />
            <br />
          </div>
        )}

        <div className="ufrm-frame_grid">
          {unsubMsg == "" && (
            <div className="ufrm-frame_grid_block ">
              <h3
                id="page-title"
                className="ufrm-frame_title ufrm-title_uline_dark"
              >
                Contact Us
              </h3>
              <br />
              <div className="content__body">
                <ContactForm
                  onSave={handleSave}
                  id={user?.sub}
                  email={user?.email}
                  is_contact_form={true}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
};
