import React, { useEffect, useState } from "react";

type Props = {
  session_id: number | undefined;
  cik: string | undefined;
  filing_code: string | undefined;
};

export function ImageComponent({ session_id, cik, filing_code }: Props) {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

  const imageUrl = `${apiServerUrl}/api/messages/sec/reports/stock_${session_id}_${cik}_${filing_code}.png`;
  const [img, setImg] = useState<any>();

  const fetchImage = async () => {
    const res = await fetch(imageUrl);
    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    setImg(imageObjectURL);
  };

  useEffect(() => {
    fetchImage();
  }, []);

  return (
    <>
      <img src={img} alt="icons" className="stock_return_image" />
    </>
  );
}
