import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../images/logo.png";
import { NavBarTab } from "./nav-bar-tab";

export const NavBarBrand: React.FC = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/">
        <img
          className="nav-bar__logo"
          src={logo}
          alt="logo"
          width="50px"
          height="50px"
        />
      </NavLink>
      <div className="nav-bar__tabs">
        <NavBarTab path="/" label="eDataPole - Business Connect" />
      </div>
    </div>
  );
};
