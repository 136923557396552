export const selectStyle = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: "#fff",
    borderColor: "#9e9e9e",
    minHeight: "26px",
    height: "26px",
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: "26px",
    padding: "0 6px",
  }),

  input: (provided: any, state: any) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state: any) => ({
    display: "none",
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: "26px",
  }),
};
