import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { PageLayout } from "../components/page-layout";
import {
  fetchNewBusinesses,
  getProfileResource,
} from "../services/message.service";
import { FindBsnItem } from "../models/find-bsn-item";
import { downloadCSV } from "../functions/download-csv";
import { getMachineId } from "../functions/get-machine-id";

import { gridStyle } from "../custom-styles/grid-style";

import { NewBsnDetailComponent } from "../components/new-bsn-detail-component";
import { NewBsnFilter } from "../components/new-bsn-filter";
import { MessageNewBsnParams } from "../models/message-new-bsn-params";
import { isMobile } from "react-device-detect";

import { MessageUserProfile } from "../models/message-user-profile";

export const BusinessGridPage: React.FC = () => {
  // createTheme creates a new theme named solarized that overrides the build in dark theme
  document.title = "New registered companies";

  const { user, getAccessTokenSilently } = useAuth0();

  const [message, setMessage] = useState<FindBsnItem[]>([]);
  /* user profile */
  const [error, setError] = useState<string>("");
  const [accessToken, setAccessToken] = useState("n/a");
  const [userMessage, setUserMessage] = useState<MessageUserProfile>();
  const [isLoading, setIsLoading] = useState(true);
  const [filtState, setFiltState] = useState("CT");

  const [currentSearchValue, setCurrentSearchValue] = useState("");

  const [pending, setPending] = React.useState(false);
  createTheme(
    "solarized",
    {
      rows: {
        fontSize: "10px",
      },
      text: {
        primary: "black",
        secondary: "red",
      },
      background: {
        default: "#e1f7f5",
      },
      context: {
        /*background: "#cb4b16",*/
        background: "#000b16",
        text: "black",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "light"
  );
  const bsnGridColumn = [
    {
      /*name: "Name",*/
      name: <div>Company Name</div>,
      selector: (row: any) => row.name,
      sortable: true,
      width: "350px",
    },
    {
      name: "Corp Date",
      selector: (row: any) => row.corp_date,
      sortable: true,
      width: "150px",
    },

    {
      name: "Industry",
      selector: (row: any) => row.industry,
      sortable: true,
      width: "200px",
    },
    {
      name: "NAICS",
      selector: (row: any) => row.naics,
      sortable: true,
      omit: isMobile,
    },
    {
      name: "Address",
      selector: (row: any) =>
        row.addrs + ", " + row.city + ", " + row.state + " " + row.zip,
      width: "350px",
      omit: isMobile,
    },
    {
      name: "EMail",
      selector: (row: any) => row.email,
      sortable: true,
      omit: true,
    },

    {
      name: "ID Number",
      selector: (row: any) => row.dos_id,
      sortable: true,
      omit: true,
      cell: (row: any) => (
        <a className="table__hyperlink" href={row.href} target="_blank">
          {row.dos_id}
        </a>
      ),
    },
  ];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    Search(e.target.value);
  };

  const Search = (v: string) => {
    if (message == null) return;
    let searchValue: Boolean;
    let nameValue: Boolean;
    let addrsValue: Boolean;
    let cityValue: Boolean;
    setCurrentSearchValue(v);
    const newRows = message!.filter((row) => {
      if (row.name != null)
        nameValue = row.name.toString().toLowerCase().includes(v.toLowerCase());
      if (row.addrs != null)
        addrsValue = row.addrs.toLowerCase().includes(v.toLowerCase());
      if (row.city != null)
        cityValue = row.city.toLowerCase().includes(v.toLowerCase());

      if (nameValue) {
        searchValue = nameValue;
      } else if (addrsValue) {
        searchValue = addrsValue;
      } else {
        searchValue = cityValue;
      }

      return searchValue;
    });
    setFiltData(newRows);
  };

  async function handleSubmit(bsn: MessageNewBsnParams) {
    setMessage([]);
    setFiltData([]);
    setPending(true);

    if (user) bsn.api_key = userMessage?.api_key!;

    bsn.device_id = getMachineId();
    setFiltState(bsn.state);
    const { data, error } = await fetchNewBusinesses(bsn);
    setPending(false);
    setMessage(data?.list!);
    setCurrentSearchValue("");
    setFiltData(data?.list!);

    /*setCurrentSearchValue(savedSearchValue);*/
    //setFiltData(data?.list!);
  }

  /*{props?.data?.name}*/
  const ExpandableComponent = (props: any) => (
    <div>
      <NewBsnDetailComponent
        state={filtState}
        dos_id={props?.data?.dos_id}
        api_key={userMessage?.api_key!}
      />{" "}
    </div>
  );
  // Handle Search
  const [filtData, setFiltData] = useState(message);

  const getSubHeaderComponent = () => {
    return (
      <div className="">
        <input
          type="search"
          className="ufrm-style-input-field"
          placeholder="Search"
          onChange={handleSearch}
          value={currentSearchValue}
        />
        {"    "}
        <button
          onClick={() => downloadCSV(filtData)}
          className="bsn-flt-style_button_csv"
        >
          Export to CSV
        </button>
      </div>
    );
  };

  console.log(getMachineId());
  useEffect(() => {
    if (!user) {
      setIsLoading(false);
      return;
    }
    let isMounted = true;
    const getUserMessage = async () => {
      const accessToken = await getAccessTokenSilently();

      const { data, error } = await getProfileResource(
        accessToken,
        user?.email
      );

      if (!isMounted) {
        return;
      }
      if (data) {
        let o: MessageUserProfile = data as MessageUserProfile;
        setUserMessage(o);
        setAccessToken(accessToken);
        setIsLoading(false);
      }

      if (error) {
        setError(JSON.stringify(error, null, 2));
      }
    };

    getUserMessage();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <PageLayout>
      <div className="ufrm-frame">
        <div className="content__body">
          <NewBsnFilter
            onSave={handleSubmit}
            id={user?.sub}
            pref_industry_list={
              userMessage != null
                ? userMessage?.pref_industry_list!
                : "0,11,21,22,23,31,32,33,42,44,45,48,49,51,52,53,54,55,56,61,62,71,72,81,92"
            }
          />
        </div>

        <div className="content__body">
          <DataTable
            columns={bsnGridColumn}
            data={filtData}
            theme="solarized"
            persistTableHead={true}
            pagination
            subHeader
            subHeaderComponent={getSubHeaderComponent()}
            customStyles={gridStyle}
            progressPending={pending}
            expandableRows
            expandableRowsComponent={ExpandableComponent}
            /*selectableRows*/
          />
        </div>
        {!user && (
          <>
            <div className="page-footer-info__button">
              <a
                id="create-account-button"
                className="bsn-flt-style_button"
                href="/profile"
                rel="noreferrer noopener"
              >
                Limited to 20 rows. Sign up for FREE plan to get full access
                &#8658;
              </a>
            </div>
          </>
        )}
      </div>
    </PageLayout>
  );
};
