import React, { FC, Fragment } from "react";

export const SrvEmailTab: FC<{}> = () => {
  const lob = "https://www.lob.com";
  const stannp = "https://www.stannp.com";
  const sec =
    "https://www.sec.gov/Archives/edgar/data/1591890/000149315224024369/0001493152-24-024369-index.html";
  return (
    <Fragment>
      <h3>New registered entities - eMail Delivery Service.</h3>
      <p>
        A weekly email delivery service that provides you with the freshest data
        on newly registered companies! This service is designed to keep you
        ahead of the competition by giving you early access to contact
        information for new businesses.
      </p>

      <p>
        <span className="svr-style-label">Here's what you can expect:</span>
        <span className="svr-style-label-span">Weekly Delivery</span>
        Every week, you will receive an email containing a CSV file packed with
        up-to-date contact information of newly registered companies.
      </p>
      <p>
        <span className="svr-style-label-span">Compatibility</span>
        The files are in CSV format which is perfectly compatible with lead
        campaign mail services like{" "}
        <a
          className="edata_a t-bold"
          href={lob}
          rel="noopener noreferrer"
          target="_blank"
        >
          Lob
        </a>{" "}
        and{" "}
        <a
          className="edata_a t-bold"
          href={stannp}
          rel="noopener noreferrer"
          target="_blank"
        >
          {" "}
          Stannp{" "}
        </a>
        .
      </p>
      <p>
        <span className="svr-style-label-span">Ease of Use</span>
        Simply integrate this information into your existing marketing
        strategies to enhance your outreach and engagement efforts.
      </p>
      <h3>
        Enhance Your Investment Strategy with Real-Time SEC Filing Notifications
        and AI Insights.
      </h3>

      <p>
        <span className="svr-style-label-span">
          Real-Time SEC Filing Notifications
        </span>
        Never miss an update with our real-time SEC filing notification service.
        Tailor your preferences to receive instant alerts when a new SEC form is
        filed for your preselected list of companies. This timely information
        ensures you're always informed about significant financial events and
        disclosures that could impact your investment decisions.
      </p>
      <p>
        <span className="svr-style-label-span">
          AI-Predicted Return Analysis
        </span>
        We're pushing the boundaries of financial analysis by incorporating AI
        technologies to predict returns based on SEC filing embeddings. This
        powerful tool analyzes complex data from SEC filings to provide insights
        into potential returns, allowing you to add a layer of predictive
        analytics to your risk assessment toolkit.
      </p>
    </Fragment>
  );
};
