import React, { useEffect } from "react";

import { updateSubscription } from "../../services/message.service";
import { MessageSubscription } from "../../models/message-subscription";

import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

import { DISPATCH_ACTION } from "@paypal/react-paypal-js";

interface PaypalProps {
  type: string;
  plan_id: string /*"P-9E437973XN904332LM3OZCGQ",*/;
  custom_id: string;
  accessToken: string;
  onApprove: (subscriptionID: string) => void;
}
/*https://paypal.github.io/react-paypal-js/?path=/docs/getting-started--page */
export const PaypalButtonWrapper = ({
  type,
  plan_id,
  custom_id,
  accessToken,
  onApprove,
}: PaypalProps) => {
  const [{ options }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: DISPATCH_ACTION.RESET_OPTIONS,
      value: {
        ...options,
        intent: "subscription",
      },
    });
  }, [type]);

  if (custom_id == null) {
    console.log("PayPal button custom_id == null");
    return null;
  }

  return (
    <PayPalButtons
      createSubscription={(data, actions) => {
        return actions.subscription
          .create({
            plan_id: plan_id,
            custom_id: custom_id,
          })
          .then((subscriptionID) => {
            // Your code here after create the order
            console.log("subscriptionID");
            console.log(subscriptionID);
            return subscriptionID;
          });
      }}
      style={{
        label: "subscribe",
        shape: "rect",
        color: "blue",
        layout: "vertical",
      }}
      onCancel={(data, actions) => {
        console.log("OrderCancel");
        console.log(data);
        return;
      }}
      onApprove={async (data, actions) => {
        console.log("OrderApproved");
        console.log(data);
        updateSubscription(accessToken, {
          subscr_type: 1,
          paypal_subscr_id: data.subscriptionID,
        } as MessageSubscription);
        onApprove(data.subscriptionID!);
        return;
      }}
    />
  );
};
