import React from "react";
import { useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { ValidationError } from "./validation-error";
import { MessageUserProfile } from "../models/message-user-profile";
interface UserProfileProps {
  user: MessageUserProfile;
  onSave: (newPost: MessageUserProfile) => void;
}

export const UserProfileForm: React.FC<UserProfileProps> = ({
  user,
  onSave,
}) => {
  const [userProfile, setUserProfile] = useState<MessageUserProfile>(user);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<MessageUserProfile>();
  function getEditorStyle(fieldError: FieldError | undefined) {
    return fieldError
      ? "ufrm-style-input-field b-red"
      : "ufrm-style-input-field";
  }
  return (
    <div className="ufrm-frame">
      <form noValidate onSubmit={handleSubmit(onSave)}>
        <div className="ufrm-frame_grid">
          <div className="ufrm-frame_grid_block">
            <h3 className="ufrm-frame_title ufrm-title_uline_dark">Profile:</h3>
            <div className="disp_none">
              <label className="ufrm-style-label" htmlFor="title">
                <span className="ufrm-style-label-span">ID:</span>
              </label>
              <input
                placeholder="id"
                type="text"
                id="id"
                {...register("id", {
                  required: false,
                })}
                className="ufrm-style-input-field ufrm-style-input_disable"
                value={userProfile.id}
              />
            </div>
            <div className="ufrm-style-row">
              <label className="ufrm-style-label" htmlFor="title">
                <span className="ufrm-style-label-span">EMail:</span>
              </label>
              <input
                placeholder="EMail"
                type="text"
                id="email"
                {...register("email", {
                  required: false,
                })}
                className="ufrm-style-input-field ufrm-style-input_disable"
                value={userProfile.email}
              />
            </div>

            <div className="ufrm-style-row">
              <label className="ufrm-style-label" htmlFor="title">
                <span className="ufrm-style-label-span">
                  First Name:
                  <span className="t-red">*</span>
                </span>
              </label>
              <input
                placeholder="First Name"
                type="text"
                id="fname"
                {...register("fname", {
                  required: "First name is required",
                })}
                className={getEditorStyle(errors.fname)}
                defaultValue={userProfile.fname}
                onChange={(e) =>
                  setUserProfile({ ...userProfile, fname: e.target.value })
                }
              />
              <ValidationError fieldError={errors.fname} />
            </div>
            <div className="ufrm-style-row">
              <label className="ufrm-style-label">
                <span className="ufrm-style-label-span">
                  Last Name:
                  <span className="t-red">*</span>
                </span>
              </label>
              <input
                placeholder="Last Name"
                id="lname"
                {...register("lname", {
                  required: "Last name is required",
                })}
                className={getEditorStyle(errors.lname)}
                value={userProfile.lname}
                onChange={(e) =>
                  setUserProfile({ ...userProfile, lname: e.target.value })
                }
              />
              <ValidationError fieldError={errors.lname} />
            </div>
            <div className="ufrm-style-row">
              <label className="ufrm-style-label">
                <span className="ufrm-style-label-span">Company Name:</span>
              </label>
              <input
                id="company_name"
                {...register("company_name", {
                  required: false,
                })}
                className={getEditorStyle(errors.company_name)}
                value={userProfile.company_name}
                onChange={(e) =>
                  setUserProfile({
                    ...userProfile,
                    company_name: e.target.value,
                  })
                }
              />
            </div>
            <div className="ufrm-style-row">
              <label className="ufrm-style-label">
                <span className="ufrm-style-label-span">
                  Tell us what additional services / features would you want.
                  And we might able to help.
                </span>
              </label>
              <textarea
                id="notes"
                {...register("notes", {
                  required: false,
                })}
                className="ufrm-style-textarea-field"
                defaultValue={userProfile.notes}
                onChange={(e) =>
                  setUserProfile({ ...userProfile, notes: e.target.value })
                }
              />
            </div>
            <div className="ufrm-style-row">
              <label className="ufrm-style-label">
                <span className="ufrm-style-label-span"> </span>
              </label>
              <button
                type="submit"
                disabled={isSubmitting}
                className="ufrm-style_button"
              >
                Submit
              </button>
              {isSubmitSuccessful && (
                <div role="alert" className="ufrm-success">
                  The profile was successfully saved
                </div>
              )}
            </div>
          </div>
          <div className="ufrm-frame_grid_block">
            <h3 className="ufrm-frame_title ufrm-title_uline_dark">
              Access to Services:
            </h3>

            <div className="ufrm-style-row">
              <label className="ufrm-style-label">
                <input
                  type="checkbox"
                  className="ufrm-style-checkbox-field"
                  id="api_service"
                  {...register("api_service", {
                    required: false,
                  })}
                  checked={userProfile.api_service}
                  onChange={(e) =>
                    setUserProfile({
                      ...userProfile,
                      api_service: e.target.checked,
                    })
                  }
                />
                <span className="ufrm-style-label-span ufrm-span-200px">
                  API:
                </span>
              </label>
            </div>
            <div className="ufrm-style-row">
              <label className="ufrm-style-label">
                <input
                  type="checkbox"
                  className="ufrm-style-checkbox-field"
                  id="email_service_sec"
                  {...register("email_service_sec", {
                    required: false,
                  })}
                  checked={userProfile.email_service_sec}
                  onChange={(e) =>
                    setUserProfile({
                      ...userProfile,
                      email_service_sec: e.target.checked,
                    })
                  }
                />
                <span className="ufrm-style-label-span ufrm-span-200px">
                  Sec (EDGAR) New Filing Notification:
                </span>
              </label>
            </div>
            <div className="ufrm-style-row">
              <label className="ufrm-style-label" htmlFor="title">
                <span className="ufrm-style-label-span">Ticker list:</span>
              </label>
              <textarea
                id="ticker_list"
                className="ufrm-style-textarea-field"
                {...register("ticker_list", {
                  required: false,
                })}
                value={userProfile.ticker_list}
                onChange={(e) =>
                  setUserProfile({
                    ...userProfile,
                    ticker_list: e.target.value,
                  })
                }
              />
            </div>

            <div className="ufrm-style-row">
              <label className="ufrm-style-label">
                <input
                  type="checkbox"
                  className="ufrm-style-checkbox-field"
                  id="email_service"
                  {...register("email_service", {
                    required: false,
                  })}
                  checked={userProfile.email_service}
                  onChange={(e) =>
                    setUserProfile({
                      ...userProfile,
                      email_service: e.target.checked,
                    })
                  }
                />
                <span className="ufrm-style-label-span ufrm-span-200px">
                  New Company Email delivery:
                </span>
              </label>
            </div>

            <div className="ufrm-style-row">
              <label className="ufrm-style-label" htmlFor="title">
                <span className="ufrm-style-label-span">State list:</span>
              </label>
              <input
                type="text"
                id="state_list"
                className="ufrm-style-input-field ufrm-style-input_disable"
                {...register("state_list", {
                  required: false,
                })}
                value={userProfile.state_list}
                onChange={(e) =>
                  setUserProfile({ ...userProfile, state_list: e.target.value })
                }
              />
            </div>
            <div className="ufrm-style-row">
              <label className="ufrm-style-label">
                <span className="ufrm-style-label-span"> </span>
              </label>
              <button
                type="submit"
                disabled={isSubmitting}
                className="ufrm-style_button"
              >
                Submit
              </button>
              {isSubmitSuccessful && (
                <div role="alert" className="ufrm-success">
                  The profile was successfully saved
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
