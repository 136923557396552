import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useEffect, useState } from "react";

import { NavBar } from "../components/navigation/desktop/nav-bar";
import { MobileNavBar } from "../components/navigation/mobile/mobile-nav-bar";
import { PageLayout } from "../components/page-layout";
import { SubsPlan } from "../components/subs-plan";

import { MessageUserProfile } from "../models/message-user-profile";

import { getProfileResource } from "../services/message.service";

interface PlanItem {
  [key: string]: string;
}

export const PricingPage: React.FC = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [userProfile, setUserProfile] = useState<MessageUserProfile>();
  const [subscrType, setSubscrType] = useState(-1);
  const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
  const paypalPlanId = process.env.REACT_APP_PAYPAL_PLAN_ID;
  /*  const testClientId =
    "AWG0oUSHEj1gCYgrFU83nXRSKLLpl4dA9Cl2DsXMTflxEO7qlePixoBkeFKcjbQ8RSL8hgKrw6kDy27F";
  const prodClientId =
    "AcJrvHkJajsb_EHGGXdm7i8qeANG4Wz4o-SCq8RDpYRDod904NdUna3uucPAenBzMN_s2c-jVRxWw03X";

  const testPlanId = "P-2SR50777MG745005TM3QBHXQ";
  const prodPlanId = "P-9E437973XN904332LM3OZCGQ";*/
  const initialOptions = {
    clientId: paypalClientId!,
    vault: true,
    intent: "subscription",
    debug: false,
  };

  const planFeatures = [
    {
      id: 0,
      list: {
        title: "Free Plan",
        description: "Newly registered companies information",
        descriptionTips:
          "Access to  mail address, agent and principal names. States FL, NY, VA and CT",
        price: "$0",
        featur1: "Website access - search, filter -  limited",
        featur1Tips: "Limited to maximum 500 entities per request.",
        featur2: "Extract to CSV",
        featur2Tips: "",
        featur3: "API access - for developers",
        featur3Tips: "Limited to maximum 500 entities per request",
        featur4: "",
        featur4Tips: "",
        featur5: "",
        featur5Tips: "Limited to maximum 500 entities per request",
        paypalClientId: "",
      },
    },
    {
      id: 1,
      list: {
        title: "Plus Plan - first 7 days free",
        description: "Newly registered companies information",
        descriptionTips:
          "Access to  EMail and Mail address, agent and principal names. State FL, NY, VA and CT",
        price: "$12 billed monthly",
        featur1: "Website access - search, filter - unlimited",
        featur1Tips: "",
        featur2: "Extract to CSV - unlimited",
        featur2Tips: "",
        featur3: "API access  - unlimited ",
        featur3Tips: "",
        featur4: "Email notification when new companies are registered",
        featur4Tips:
          "Triggered when new company fall into specific user preferred industry categories",

        featur5: "Company Email addresses  for states FL and CT",
        featur5Tips: "",
        paypalClientId: paypalPlanId!,
      },
    },
    {
      id: 2,
      list: {
        title: "Enterprise Plan",
        description:
          "Data and APIs for custom business workflows. Includes all Plus features",
        descriptionTips: "",
        price: " - Contact Us",
        featur1:
          "Data extraction from SEC filing  10-K, 3, 4, 5 , 8-K, 13f forms",
        featur1Tips:
          "Subscribe for real time filing notification and use 10-K, 3, 4, 5 , 8-K, 13f form extracted information in risk analysis and profit prediction.  Securities and Exchange Commission (SEC)",
        featur2: "API Integration",
        featur2Tips:
          "Connect directly with our services through our API. If you don’t have a developer on hand, we offer a no-code solution to get you started effortlessly",
        featur3: "Custom Solutions",
        featur3Tips:
          "Unique business challenges require unique solutions. If you require a tailored approach, don't hesitate to reach out to discuss how we can address your specific needs",
        featur4: "",
        featur4Tips: "",
        featur5: "",
        featur5Tips: "",
        paypalClientId: "",
      },
    },
  ];

  const [accessToken, setAccessToken] = useState<string>("");
  async function onApprove(subsId: string) {
    userProfile!.subscr_type = 1;
    setUserProfile(userProfile);
  }

  useEffect(() => {
    let isMounted = true;
    const getMessage = async () => {
      let aToken = "";
      if (!isAuthenticated) {
        isMounted = true;
        setIsLoading(false);
        console.log("Not Authenticated");
        return;
      }
      try {
        aToken = await getAccessTokenSilently();
      } catch (error) {
        console.log(`axios err.error`, error);

        /*if (err.error === 'login_required') {
        window.location.href = auth0.loginWithRedirect()
    } else {
        // throw err
    }*/
      }

      if (aToken === "") {
        isMounted = true;
        setIsLoading(false);
        return;
      }
      const { data, error } = await getProfileResource(aToken, user?.email);

      if (!isMounted) {
        return;
      }
      if (data) {
        let userP = data as MessageUserProfile;
        setUserProfile(userP);
        setAccessToken(aToken);
        setIsLoading(false);
        setSubscrType(userP.subscr_type);
      }

      if (error) {
        setError(JSON.stringify(error, null, 2));
      }
    };

    getMessage();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <PageLayout>
      <div className="page-layout">
        <div className="page-layout__content">
          <PayPalScriptProvider options={initialOptions}>
            <div className="subs-plan-style">
              <div className="subs-plan-table">
                <div className="subs-plan-table-row">
                  {planFeatures.map((feature, i) => (
                    <div key={i} className="subs-plan-table-cell">
                      <SubsPlan
                        plan={feature.list}
                        customer_id={user?.sub!}
                        accessToken={accessToken}
                        planId={feature.id}
                        subscribeType={subscrType}
                        onApprove={onApprove}
                      />
                    </div>
                  ))}
                </div>{" "}
              </div>
            </div>
          </PayPalScriptProvider>
        </div>
      </div>
    </PageLayout>
  );
};
