import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { Route, Routes } from "react-router-dom";
import { AdminPage } from "./pages/admin-page";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import { DashboardPage } from "./pages/dashboard-page";
import { FindBusinessPage } from "./pages/find-business-page";
import { BusinessGridPage } from "./pages/business-grid-page";
import { AboutPage } from "./pages/about-page";
import { ServicesPage } from "./pages/services-page";
import { PricingPage } from "./pages/pricing-page";
import { ContactUsPage } from "./pages/contact-us";
import { TraceCustomer } from "./services/message.service";

export const App: React.FC = () => {
  const { isLoading } = useAuth0();
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  let cust_id = params.get("cust");
  if (cust_id != null) TraceCustomer(cust_id, params.get("unsub"));

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="/profile"
        element={<AuthenticationGuard component={ProfilePage} />}
      />
      <Route path="/about" element={<AboutPage />} />

      <Route path="/services" element={<ServicesPage />} />

      <Route
        path="/findbisiness"
        element={<AuthenticationGuard component={FindBusinessPage} />}
      />
      <Route path="/newbisinesses" element={<BusinessGridPage />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/contact" element={<ContactUsPage />} />

      <Route
        path="/dashboard"
        element={<AuthenticationGuard component={DashboardPage} />}
      />

      <Route
        path="/admin"
        element={<AuthenticationGuard component={AdminPage} />}
      />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
