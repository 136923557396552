import React, { FC, Fragment } from "react";
import { CopyBlock, dracula } from "react-code-blocks";
var error_data_1 = {
  error: "invalid_token",
  error_description: "Signature has expired",
  message: "Bad credentials.",
  response_status: 401,
  response_reason: "UNAUTHORIZED",
};
var error_data_2 = {
  response_status: 999,
  response_reason:
    "ConnectionRefusedError(10061, 'No connection could be made because the target machine actively refused it', None, 10061, None)",
};

var data = {
  list: [
    {
      cik: "0000066740",
      cmp_name: "3M CO",
      sic: "3841",
      term: "10-K",
      updated: "2024-02-07 09:25:16-05:00",
      full_10k_url:
        "https://www.sec.gov/Archives/edgar/data/66740/000006674024000016/mmm-20231231.htm",
      accession_number: "0000066740-24-000016",
      data: "Item 1. Business 3M Company was incorporated in 1929 under the laws of the State of Delaware to continue operations begun in 1902. The Company s ticker symbol is MMM. As used herein, the term  3M  or  Company...",
    },
    {
      cik: "0000066740",
      cmp_name: "3M CO",
      sic: "3841",
      term: "10-K",
      updated: "2023-02-08 12:59:49-05:00",
      full_10k_url:
        "https://www.sec.gov/Archives/edgar/data/66740/000006674023000014/mmm-20221231.htm",
      accession_number: "0000066740-23-000014",
      data: "Item 1. Business 3M Capital expenditures for similar projects are presently expected to approach approximately $646 ...",
    },
  ],
  response_status: 200,
  response_reason: "OK",
};

export const SrvSecExtractApiTab: FC<{}> = () => {
  const github =
    "https://github.com/Ikopeikin32/extract-sec-edgar-filing-sample";
  const sample_api_link =
    "http://edatapole.com/api/messages/sec/edgar_extract?ticker=MMM&years_back=2&sections=1A&api_key=YOUR_API_KEY";
  const code_sample: string = `
"""Module providing a function making  call eDataPole REST API."""
import sys
from edgar_data_collector import *
from edgar_data_request import *

if __name__ == "__main__":
    # replace with your access code.
    # Find your api key on https://www.eDataPole.com/profile .
    api_key = "eyJ0eXAiOi.....JKV11qCXZzLx6dOnLKkYks"
    #Request for Form Fetching Based on regexp matching.
    keywords = None  
    ticker = "AMD"
    months_back = 12
    sections = "1A,7A"  # used only for 10-K
    forms = "10-K"  # available forms - "10-K,3,4,5,8-K,13f

    dr = edgar_data_request()

    json_response = dr.extract_edgar_forms_by_ticker(
        api_key, ticker, months_back, forms, sections
    )

    print(json_response)
    if json_response[RSP_STATUS] != 200:
        print("Exception fetching data")
        print(json_response[RSP_STATUS], json_response[RSP_REASON])
        sys.exit()
    # if request is successful
    data = edgar_data_collector()
    data.response_to_pd(json_response)
    # extract to csv files
    data.to_csv()
     `;

  return (
    <Fragment>
      <h3>
        Securities and Exchange Commission (SEC) 10-K,3,4,5,8-K,13f Forms Filing
        extract API
      </h3>

      <p>
        Extratced data is in plain TXT format that you can use in AI semantic
        analyses to predict a profit.
      </p>
      <p>API Endpoint: https://www.edatapole.com//api/messages/sec/edgar_extract</p>
      <ul className="hero-banner--ul">
        Request parameters:
        <li className="hero-banner--li">
          ticker or cik : use Company tickers or The Central Index Key (CIK).
          use AMD or 0001903058 for example{" "}
        </li>
        <li className="hero-banner--li">
          months_back : Extract filed months_back months back. Not greater than
          24 month. Submit an request if you need more them 12 month
        </li>
        <li className="hero-banner--li">
          forms : Comma separated list of supported SEC filing
          forms:10-K,3,4,5,8-K,13f
        </li>
        <li className="hero-banner--li">
          sections : Comma separated list of supported 10-K SEC filing sections:
          1, 1A, 1B, 1C, 2, 3, 4, 5, 6, 7, 7A, 8, 9, 9A, 9B, 10, 11, 12, 13, 14,
          15. Used only for 10-K
        </li>
      </ul>
      <p>
        Sample of URL parameters string is
        <strong>
          {" "}
          <a
            className="edata_a"
            href={sample_api_link}
            rel="noopener noreferrer"
            target="_blank"
          >
            http://edatapole.com/api/messages/sec/edgar_extract?ticker=AMDmonths_back=5&forms=10-K&sections=1A&api_key=YOUR_API_KEY
          </a>
        </strong>
        {"   "}This is a demo key. Find your API Key on your profile page.
      </p>
      <p>
        <strong className="svr-style-label">
          {" "}
          <a
            className="edata_a"
            href={github}
            rel="noopener noreferrer"
            target="_blank"
          >
            You can find a sample code to extract 10-K,3,4,5,8-K,13f forms and
            convert to csv file on GitHub
          </a>
        </strong>
      </p>
      <h3>Get company information (CIK, SIC,ticker,title) by ticker or CIK</h3>
      <p>Basically it allows to map ticker vs CIK and vice versa.</p>
      <p>API Endpoint: https://www.edatapole.com/api/messages/sec/edgar_extract</p>
      <ul className="hero-banner--ul">
        One of the following:
        <li className="hero-banner--li">
          ticker : Company tickers. MMM ( 3M CO) for example{" "}
        </li>
        <li className="hero-banner--li">
          cik : The Central Index Key (CIK) is used on the SEC's computer
          systems to identify corporations.
        </li>
      </ul>

      <p>
        Sample of URL parameters string is
        <strong>
          {" "}
          http://edatapole.com/api/messages/sec/edgar_get_cmp?ticker=MMM&api_key=YOUR_API_KEY
        </strong>
        {"   "} or by CIK {"   "}
        <strong>
          http://edatapole.com/api/messages/sec/edgar_get_cmp?cik=0001911545&api_key=YOUR_API_KEY
        </strong>
        {"   "}You can find a sample code on{" "}
        <a
          className="edata_a"
          href={github}
          rel="noopener noreferrer"
          target="_blank"
        >
          GitHub
        </a>
      </p>

      <div>
        <CopyBlock text={code_sample} language="python" theme={dracula} />
      </div>
      <p></p>
      <p>
        <strong>
          The response in in JSON format that list filing details and extratced
          in TXT format requested sections.
        </strong>
      </p>
      <div>
        <pre className="edata_pre"> {JSON.stringify(data, null, 2)}</pre>
      </div>
      <p>
        <strong>Samples of responses in case of failure are below:</strong>
      </p>
      <div>
        <span>
          <strong>Expired/invalid token:</strong>
        </span>
        <pre className="edata_pre">{JSON.stringify(error_data_1, null, 2)}</pre>
      </div>
      <div>
        <span>
          <strong>Connection failure:</strong>
        </span>

        <pre className="edata_pre">{JSON.stringify(error_data_2, null, 2)}</pre>
      </div>
    </Fragment>
  );
};
