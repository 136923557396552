import React from "react";
import { useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { ValidationError } from "./validation-error";
import { MessageTicket } from "../models/message-ticket";
interface ContactProps {
  id?: string;
  email?: string;
  is_contact_form: boolean;
  onSave: (newPost: MessageTicket) => void;
}

export const ContactForm: React.FC<ContactProps> = ({
  id,
  email,
  onSave,
  is_contact_form,
}) => {
  let tmp = {
    id: id,
    email: email,
  };

  const [ticket, setUserProfile] = useState<MessageTicket>(
    tmp as MessageTicket
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<MessageTicket>();
  function getEditorStyle(fieldError: FieldError | undefined) {
    return fieldError
      ? "ufrm-style-input-field b-red"
      : "ufrm-style-input-field";
  }
  return (
    <div>
      <form noValidate onSubmit={handleSubmit(onSave)}>
        <div className="ufrm-style-row">
          <label className="ufrm-style-label" htmlFor="title">
            <span className="ufrm-style-label-span">
              EMail:<span className="t-red">*</span>
            </span>
          </label>
          <input
            placeholder="EMail"
            type="text"
            id="email"
            {...register("email", {
              required: "EMail is required",
            })}
            className="ufrm-style-input-field"
            value={ticket.email}
          />
        </div>

        <div className="ufrm-style-row">
          <label className="ufrm-style-label" htmlFor="title">
            <span className="ufrm-style-label-span">
              First Name:
              <span className="t-red">*</span>
            </span>
          </label>
          <input
            placeholder="First Name"
            type="text"
            id="fname"
            {...register("fname", {
              required: "First name is required",
            })}
            className={getEditorStyle(errors.fname)}
            onChange={(e) =>
              setUserProfile({ ...ticket, fname: e.target.value })
            }
          />
          <ValidationError fieldError={errors.fname} />
        </div>
        <div className="ufrm-style-row">
          <label className="ufrm-style-label">
            <span className="ufrm-style-label-span">
              Last Name:
              <span className="t-red">*</span>
            </span>
          </label>
          <input
            placeholder="Last Name"
            id="lname"
            {...register("lname", {
              required: "Last name is required",
            })}
            className={getEditorStyle(errors.lname)}
            value={ticket.lname}
            onChange={(e) =>
              setUserProfile({ ...ticket, lname: e.target.value })
            }
          />
          <ValidationError fieldError={errors.lname} />
        </div>
        {!is_contact_form && (
          <div className="ufrm-style-row">
            <label className="ufrm-style-label">
              <span className="ufrm-style-label-span">Company Name:</span>
            </label>

            <input
              id="company_name"
              {...register("company_name", {
                required: false,
              })}
              className={getEditorStyle(errors.company_name)}
              value={ticket.company_name}
              onChange={(e) =>
                setUserProfile({ ...ticket, company_name: e.target.value })
              }
            />
          </div>
        )}

        <div className="ufrm-style-row">
          {!is_contact_form && (
            <label className="ufrm-style-label" htmlFor="title">
              <span className="ufrm-style-label-span">
                Interested in:<span className="t-red">*</span>
              </span>
            </label>
          )}
          {is_contact_form && (
            <label className="ufrm-style-label" htmlFor="title">
              <span className="ufrm-style-label-span">
                Topic:<span className="t-red">*</span>
              </span>
            </label>
          )}

          <input
            type="text"
            id="interested_in"
            className="ufrm-style-input-field"
            {...register("interested_in", {
              required: "is required",
            })}
            onChange={(e) =>
              setUserProfile({ ...ticket, interested_in: e.target.value })
            }
          />
        </div>

        <div className="ufrm-style-row">
          <label className="ufrm-style-label">
            {!is_contact_form && (
              <span className="ufrm-style-label-span">
                Additional Information:<span className="t-red">*</span>
              </span>
            )}
            {is_contact_form && (
              <span className="ufrm-style-label-span">
                Message:<span className="t-red">*</span>
              </span>
            )}
          </label>
          <textarea
            id="details"
            {...register("notes", {
              required: "is required",
            })}
            className="ufrm-style-textarea-field"
            defaultValue={ticket.notes}
            onChange={(e) =>
              setUserProfile({ ...ticket, notes: e.target.value })
            }
          />
        </div>

        <div className="ufrm-style-row">
          <label className="ufrm-style-label">
            <span className="ufrm-style-label-span"> </span>
          </label>
          <button
            type="submit"
            disabled={isSubmitting}
            className="ufrm-style_button"
          >
            Submit
          </button>
          {isSubmitSuccessful && (
            <div role="alert" className="ufrm-success">
              The request was successfully submitted
            </div>
          )}
        </div>
        <div className="disp_none">
          <label className="ufrm-style-label" htmlFor="title">
            <span className="ufrm-style-label-span">ID:</span>
          </label>
          <input
            placeholder="id"
            type="text"
            id="id"
            {...register("id", {
              required: false,
            })}
            className="ufrm-style-input-field ufrm-style-input_disable"
            value={ticket.id}
          />
        </div>
      </form>
    </div>
  );
};
