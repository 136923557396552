import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../../constants/index";
import "../../../i18n";

export const NavBarLanguage: React.FC = () => {
  const { i18n, t } = useTranslation();
  const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };

  return (
    <div className="nav-bar__language" hidden>
      <select
        className="t-dark"
        title="langList"
        defaultValue={i18n.language}
        onChange={onChangeLang}
      >
        {LANGUAGES.map(({ code, label }) => (
          <option key={code} value={code}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};
