import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import Tabs from "../components/Tabs/Tabs";
import { SrvDataFormatTab } from "../components/srv-data-format-tab";
import { SrvApiTab } from "../components/srv-api-tab";
import { SrvEmailTab } from "../components/srv-email-tab";
import { SrvSecExtractApiTab } from "../components/srv-sec-extract-api-tab";

import { getServicesResource } from "../services/message.service";
import { MessageUserProfile } from "../models/message-user-profile";

type TabsType = {
  label: string;
  index: number;
  Component: React.FC<{}>;
}[];

// Tabs Array
const tabs: TabsType = [
  {
    label: "New Registered Entities API",
    index: 2,
    Component: SrvApiTab,
  },
  {
    label: "SEC Filing Extract API",
    index: 3,
    Component: SrvSecExtractApiTab,
  },
  {
    label: "SEC filing and new  Entities Notification",
    index: 1,
    Component: SrvEmailTab,
  },
];
export const ServicesPage: React.FC = () => {
  const [message, setMessage] = useState<MessageUserProfile>();
  const [error, setError] = useState<string>("");

  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);
  document.title =
    "SEC Filing extract/notification. New businesses, registered last month";

  useEffect(() => {
    let isMounted = true;

    const getMessage = async () => {
      const { data, error } = await getServicesResource();

      if (!isMounted) {
        return;
      }

      if (data) {
        let o: MessageUserProfile = data as MessageUserProfile;
        setMessage(o);
      }

      if (error) {
        setError(JSON.stringify(error, null, 2));
      }
    };

    getMessage();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <PageLayout>
      <div className="content-layout  txt_drk">
        <h3 id="page-title" className="content__title"></h3>

        <div className="content__body">
          <Tabs
            selectedTab={selectedTab}
            onClick={setSelectedTab}
            tabs={tabs}
          />
        </div>
      </div>
    </PageLayout>
  );
};
