import React from "react";

interface EDataFeatureProps {
  title: string;
  description: string;
  resourceUrl: string;
  icon: string;
}

export const EDataFeature: React.FC<EDataFeatureProps> = ({
  title,
  description,
  resourceUrl,
  icon,
}) => (
  <a href={resourceUrl} className="auth0-feature" rel="noopener noreferrer">
    <h3 className="auth0-feature__headline">
      <img
        className="auth0-feature__icon"
        src={icon}
        alt="external link icon"
      />
      {title}
    </h3>
    <p className="auth0-feature__description">{description}</p>
  </a>
);
