import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiResponse } from "../models/api-response";
import { UserApiResponse } from "../models/user-api-response";
import { callExternalUserApi } from "./external-user-api.service";
import { callExternalApi } from "./external-api.service";
import { callExternalDashboardApi } from "./external-dashboard-api.service";
import { callExternalFindBsnApi } from "./external-find-bsn-api.service";
import { AppError } from "../models/app-error";
import { MessageUserProfile } from "../models/message-user-profile";
import { MessageTicket } from "../models/message-ticket";
import { DashboardResponse } from "../models/dashboard-response";
import { FindBsnResponse } from "../models/find-bsn-response";
import { MessageBsnParams } from "../models/message-bsn-params";
import { MessageNewBsnParams } from "../models/message-new-bsn-params";
import { MessageSubscription } from "../models/message-subscription";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getPublicResource = async (): Promise<UserApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/messages/about`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };

  const { data, error } = (await callExternalUserApi({
    config,
  })) as UserApiResponse;

  return {
    data,
    error,
  };
};

export const updateSubscription = async (
  accessToken: string,
  subscription: MessageSubscription
): Promise<UserApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/messages/updsubs`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: subscription,
  };

  try {
    const response: AxiosResponse = await axios(config);
    const { data } = response;
    return {
      data,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      const { response } = axiosError;

      let message = "http request failed";

      if (response && response.statusText) {
        message = response.statusText;
      }
      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response && response.data && (response.data as AppError).message) {
        message = (response.data as AppError).message;
      }

      return {
        data: null,
        error: {
          message,
        },
      };
    }

    return {
      data: null,
      error: {
        message: (error as Error).message,
      },
    };
  }
};

export const saveProfileResource = async (
  accessToken: string,
  newProfileData: MessageUserProfile
): Promise<UserApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/messages/saveprofile`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: newProfileData,
  };

  try {
    const response: AxiosResponse = await axios(config);
    const { data } = response;
    return {
      data,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      const { response } = axiosError;

      let message = "http request failed";

      if (response && response.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response && response.data && (response.data as AppError).message) {
        message = (response.data as AppError).message;
      }

      return {
        data: null,
        error: {
          message,
        },
      };
    }

    return {
      data: null,
      error: {
        message: (error as Error).message,
      },
    };
  }
};

export const TraceCustomer = async (
  customer_code: string,
  unsubscribe: string | any
): Promise<UserApiResponse> => {
  let obj = { unsub: unsubscribe, cust: customer_code };

  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/messages/custtrace`,
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: obj,
  };

  try {
    const response: AxiosResponse = await axios(config);
    const { data } = response;
    return {
      data,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      const { response } = axiosError;

      let message = "http request failed";

      if (response && response.statusText) {
        message = response.statusText;
      }
      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response && response.data && (response.data as AppError).message) {
        message = (response.data as AppError).message;
      }

      return {
        data: null,
        error: {
          message,
        },
      };
    }

    return {
      data: null,
      error: {
        message: (error as Error).message,
      },
    };
  }
};

export const submitTicket = async (
  newTicket: MessageTicket
): Promise<UserApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/messages/submitticket`,
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: newTicket,
  };

  try {
    const response: AxiosResponse = await axios(config);
    const { data } = response;
    return {
      data,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      const { response } = axiosError;

      let message = "http request failed";

      if (response && response.statusText) {
        message = response.statusText;
      }
      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response && response.data && (response.data as AppError).message) {
        message = (response.data as AppError).message;
      }

      return {
        data: null,
        error: {
          message,
        },
      };
    }

    return {
      data: null,
      error: {
        message: (error as Error).message,
      },
    };
  }
};

export const getServicesResource = async (): Promise<UserApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/messages/services`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };

  const { data, error } = (await callExternalUserApi({
    config,
  })) as UserApiResponse;

  return {
    data,
    error,
  };
};

export const getProfileResource = async (
  accessToken: string,
  email: string | any
): Promise<UserApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/messages/profile`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  config.params = { email: email };
  const { data, error } = (await callExternalUserApi({
    config,
  })) as UserApiResponse;

  return {
    data,
    error,
  };
};

export const findBusinessByName = async (
  bsn: MessageBsnParams | any
): Promise<FindBsnResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/messages/find_bsn_by_name`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      /*Authorization: `Bearer ${accessToken}`,*/
    },
  };
  config.params = { name: bsn.name, state: bsn.state };
  const { data, error } = (await callExternalFindBsnApi({
    config,
  })) as FindBsnResponse;

  return {
    data,
    error,
  };
};

export const fetchNewBusinesses = async (
  bsn: MessageNewBsnParams | any
): Promise<FindBsnResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/messages/fetch_bsns`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      /*Authorization: `Bearer ${accessToken}`,*/
    },
  };
  config.params = {
    after: bsn.after,
    state: bsn.state,
    sic: bsn.sic_groups.join(),
    api_key: bsn.api_key,
    dev_id: bsn.device_id,
  };
  const { data, error } = (await callExternalFindBsnApi({
    config,
  })) as FindBsnResponse;

  return {
    data,
    error,
  };
};

export const fetchNewBusinessesDetails = async (
  bsn: MessageBsnParams | any
): Promise<FindBsnResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/messages/fetch_bsn_details`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      /*Authorization: `Bearer ${accessToken}`,*/
    },
  };
  config.params = {
    state: bsn.state,
    dos_id: bsn.dos_id,
    api_key: bsn.api_key,
    dev_id: bsn.device_id,
  };
  const { data, error } = (await callExternalFindBsnApi({
    config,
  })) as FindBsnResponse;

  return {
    data,
    error,
  };
};

export const findBusinessById = async (
  bsn: MessageBsnParams | any
): Promise<FindBsnResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/messages/find_bsn_by_id`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      /*Authorization: `Bearer ${accessToken}`,*/
    },
  };
  config.params = { id: bsn.dos_id };
  const { data, error } = (await callExternalFindBsnApi({
    config,
  })) as FindBsnResponse;

  return {
    data,
    error,
  };
};

export const getDashboardResource = async (
  accessToken: string
): Promise<DashboardResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/messages/sec/sec_filing`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalDashboardApi({
    config,
  })) as DashboardResponse;

  return {
    data,
    error,
  };
};

export const getAdminResource = async (
  accessToken: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/messages/admin`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};
