import React from "react";
import { EDataFeatures } from "src/components/edata-features";
import { HeroBanner } from "src/components/hero-banner";
import { PageLayout } from "../components/page-layout";

export const HomePage: React.FC = () => (
  <PageLayout>
    <>
      <HeroBanner />
      <EDataFeatures />
    </>
  </PageLayout>
);
