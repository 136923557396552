import React from "react";
import { eDataResource } from "../models/edata-resource";
import { PageFooterHyperlink } from "./page-footer-hyperlink";
import logo from "../images/logo.png";

export const PageFooter = () => {
  const resourceList: eDataResource[] = [
    {
      path: "http://www.dos.ny.gov",
      label: "NY Department of State",
    },
    {
      path: "https://dos.fl.gov/",
      label: "FL Department of State",
    },
    {
      path: "https://www.newyorkfed.org/",
      label: "Federal Reserve Bank",
    },
    {
      path: "https://www.sec.gov/edgar/search-and-access/",
      label: "EDGAR",
    },
  ];
  const aipredict = "https://www.aipredict-fs.com";

  return (
    <footer className="page-footer">
      <div className="page-footer-grid">
        <div className="page-footer-grid__info">
          <div className="page-footer-info__message">
            <p className="page-footer-message__headline">
              <span>This eDataPole services are brought to you by&nbsp;</span>
              <a
                className="edata_a_light"
                href={aipredict}
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="t-light_spot">
                  {" "}
                  AI Predict Financial Software
                </span>
              </a>
            </p>
            <p className="page-footer-message__description">
              <PageFooterHyperlink path="/services">
                <>
                  <span className="t-light_alum">
                    eDataPole focuses on data analysis of publicly available
                    datasets and provides access to results
                  </span>
                  <u className="t-light_alum">
                    {" "}
                    via REST API and other delivery services
                  </u>
                </>
              </PageFooterHyperlink>
            </p>
          </div>
          <div className="page-footer-info__button">
            <form
              hidden
              action="https://www.paypal.com/donate"
              method="post"
              target="_top"
            >
              <input
                type="hidden"
                name="hosted_button_id"
                value="SYQ5T9HRBVQH6"
              />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                name="submit"
                title="PayPal - The safer, easier way to pay online!"
                alt="Donate with PayPal button"
              />
              <img
                alt=""
                src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>
          </div>
          <div className="page-footer-info__resource-list txt_a1r">
            <div>Original source links:</div>
            {resourceList.map((resource) => (
              <div
                key={resource.path}
                className="page-footer-info__resource-list-item"
              >
                <PageFooterHyperlink path={resource.path}>
                  <>{resource.label}</>
                </PageFooterHyperlink>
              </div>
            ))}
          </div>
        </div>
        <div className="page-footer-grid__brand">
          <div className="page-footer-brand">
            <img
              className="page-footer-brand__logo"
              src={logo}
              alt="logo"
              width="60"
              height="60"
            />
            <PageFooterHyperlink path="/">
              <span className="t-light_alum">
                eDataPole - Business Connect.
              </span>
            </PageFooterHyperlink>
          </div>
        </div>
      </div>
    </footer>
  );
};
