import React, { EventHandler, useState } from "react";
import { PaypalButtonWrapper } from "../components/buttons/paypal-button";
import { Tooltip } from "../components/buttons/tooltip";

interface PlanItem {
  [key: string]: string;
}
interface PlanParams {
  accessToken: string;
  plan: PlanItem;
  customer_id: string;
  planId: number; // subscribed for this specific plan -
  subscribeType: number; // subscribe type 0 free, 1 - Plus, 2 - custom
  onApprove: (subscriptionID: string) => void;
}
export const SubsPlan: React.FC<PlanParams> = ({
  plan,
  customer_id,
  accessToken,
  planId,
  subscribeType,
  onApprove,
}) => {
  async function onApprovePlan(subsId: string) {
    setSubscrType(1);
    onApprove(subsId);
  }
  const [subscrType, setSubscrType] = useState(subscribeType);

  return (
    <div>
      <div className="subs-plan-style-header_row">
        <label
          className="subs-plan-heading t-light_spot"
          /*dangerouslySetInnerHTML={{ __html: plan.title }}*/
        >
          {plan.title}
        </label>
        {planId === subscrType && (
          <div className="subs-plan_your-plan">Your plan</div>
        )}
      </div>
      <div className="subs-plan-style-row">
        <label className="subs-plan-label">{plan.description}</label>
        <Tooltip disabled={false} content={plan.descriptionTips}>
          <div>&#x24BE;</div>
        </Tooltip>
      </div>
      <div className="subs-plan-style-header_row">
        <label className="subs-plan-heading t-light_spot">{plan.price}</label>
      </div>
      <div className="subs-plan-style-row">
        <label className="subs-plan-label">{plan.featur1}</label>
        <Tooltip disabled={false} content={plan.featur1Tips}>
          <div>&#x24BE;</div>
        </Tooltip>
      </div>
      <div className="subs-plan-style-row">
        <label className="subs-plan-label">{plan.featur2}</label>
        <Tooltip disabled={false} content={plan.featur2Tips}>
          <div>&#x24BE;</div>
        </Tooltip>
      </div>
      <div className="subs-plan-style-row">
        <label className="subs-plan-label">{plan.featur3}</label>
        <Tooltip disabled={false} content={plan.featur3Tips}>
          <div>&#x24BE;</div>
        </Tooltip>
      </div>
      <div className="subs-plan-style-row">
        <label className="subs-plan-label">{plan.featur4}</label>
        <Tooltip disabled={false} content={plan.featur4Tips}>
          <div>&#x24BE;</div>
        </Tooltip>
      </div>
      <div className="subs-plan-style-row">
        <label className="subs-plan-label">{plan.featur5}</label>
        <Tooltip disabled={false} content={plan.featur5Tips}>
          <div>&#x24BE;</div>
        </Tooltip>
      </div>
      <div className="subs-plan-style-last_row">
        {customer_id == null && (
          <>
            <div className="page-footer-info__button">
              <a
                id="create-account-button"
                className="button button--secondary"
                href="/profile"
                rel="noreferrer noopener"
              >
                Subscribe →
              </a>
            </div>
          </>
        )}
      </div>

      <div className="subs-plan-style-last_row">
        {plan.paypalClientId != "" &&
          customer_id != null &&
          subscrType === 0 && (
            <PaypalButtonWrapper
              type="subscription"
              plan_id={plan.paypalClientId}
              custom_id={customer_id}
              accessToken={accessToken}
              onApprove={onApprovePlan}
            />
          )}
      </div>
    </div>
  );
};
