import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import { submitTicket } from "../services/message.service";
import { MessageTicket } from "../models/message-ticket";
import { ContactForm } from "../components/contact-form";

export const AboutPage: React.FC = () => {
  const { user } = useAuth0();

  async function handleSave(newTicket: MessageTicket) {
    const { data, error } = await submitTicket(newTicket);
  }

  return (
    <PageLayout>
      <div className="ufrm-frame">
        <div className="ufrm-frame_grid">
          <div className="ufrm-frame_grid_block  ufrm-frame_grid_dark_style">
            <h2 id="1page-title" className="ufrm-frame_title  t-light_spot">
              The eDataPole Mission
            </h2>

            <div className="hero-banner__description">
              <p id="page-description">
                <span>
                  Our mission is to help to{" "}
                  <span className="t-light_spot">
                    connect new entrepreneurs
                  </span>{" "}
                  with established companies to make them more productive and
                  successful. Help to successfully plugin into new business
                  world.
                </span>
                <span>
                  We are focused on{" "}
                  <span className="t-light_spot">
                    empowering small investors
                  </span>{" "}
                  by simplifying the access to crucial public company filing
                  information. Understanding the complexities and risks involved
                  in investment decisions can be daunting, particularly for
                  individual investors. Our goal is to simplify this process and
                  support you in your investment journey.
                </span>

                <span>
                  <strong></strong>
                </span>
              </p>
            </div>
          </div>
          <div className="ufrm-frame_grid_block">
            <h3
              id="page-title"
              className="ufrm-frame_title ufrm-title_uline_dark"
            >
              Get In Touch
            </h3>
            <p className="page-description  t-dark1">
              <span>Fill out the form or email to info@aipredict-fs.com.</span>
            </p>
            <div className="content__body">
              <ContactForm
                onSave={handleSave}
                id={user?.sub}
                email={user?.email}
                is_contact_form={false}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
