import React from "react";

interface CopyProps {
  content: string;
}

export const CopyToClipboardButton: React.FC<CopyProps> = ({ content }) => {
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(content);
      console.log("Copied to clipboard:", content);
    } catch (error) {
      console.error("Unable to copy to clipboard:", error);
    }
  };

  return (
    <button className="button__copy_to_clipboard" onClick={handleCopy}>
      Copy
    </button>
  );
};
