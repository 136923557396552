import React from "react";
import { useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { ValidationError } from "./validation-error";
import { MessageBsnParams } from "../models/message-bsn-params";
interface BsnProps {
  id?: string;
  email?: string;
  onSave: (params: MessageBsnParams) => void;
}

export const FindBsnForm: React.FC<BsnProps> = ({ id, email, onSave }) => {
  let tmp = {
    name: "",
  };

  const [bsn_params, setMessageBsnParams] = useState<MessageBsnParams>(
    tmp as MessageBsnParams
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<MessageBsnParams>();
  function getEditorStyle(fieldError: FieldError | undefined) {
    return fieldError
      ? "ufrm-style-input-field b-red"
      : "ufrm-style-input-field";
  }
  return (
    <div className="ufrm-style">
      <form noValidate onSubmit={handleSubmit(onSave)}>
        <div className="ufrm-style-row">
          <label className="ufrm-style-label" htmlFor="title">
            <span className="ufrm-style-label-span">
              Company Name:
              <span className="t-red">*</span>
            </span>
          </label>
          <input
            placeholder="First Name"
            type="text"
            id="name"
            {...register("name", {
              required: "First name is required",
            })}
            className={getEditorStyle(errors.name)}
            onChange={(e) =>
              setMessageBsnParams({ ...bsn_params, name: e.target.value })
            }
          />
          <ValidationError fieldError={errors.name} />
        </div>
        <div className="ufrm-style-row">
          <label className="ufrm-style-label">
            <span className="ufrm-style-label-span"> </span>
          </label>
          <button
            type="submit"
            disabled={isSubmitting}
            className="ufrm-style_button"
          >
            Submit
          </button>
          {isSubmitSuccessful && (
            <div role="alert" className="ufrm-success">
              The request was successfully submitted
            </div>
          )}
        </div>
      </form>
    </div>
  );
};
