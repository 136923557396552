import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { NavBarTab } from "./nav-bar-tab";

export const NavBarTabs: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const tbdAdminSolved: boolean = false;
  const tbFindBsn: boolean = false;
  return (
    <div className="nav-bar__tabs">
      <NavBarTab path="/about" label="About" />
      {isAuthenticated && (
        <NavBarTab path="/dashboard" label="SEC Filings Risk Analysis" />
      )}
      <NavBarTab path="/services" label="Services" />
      <NavBarTab path="/newbisinesses" label="New Businesses" />

      <NavBarTab path="/pricing" label="Pricing" />
      <NavBarTab path="/contact" label="Contact" />
      {isAuthenticated && (
        <>
          {tbFindBsn && (
            <NavBarTab path="/findbisiness" label="Find Business" />
          )}
          <NavBarTab path="/profile" label="Profile" />
          {tbdAdminSolved && <NavBarTab path="/admin" label="Admin" />}
        </>
      )}
    </div>
  );
};
